import restaurant from "./images/mognat.jpg";
import biserica from "./images/manastirea.jpg";
import img_card from "./images/baby1.jpg";
import imgOmSuflet from "./images/5.jpg";
import imgOmSufletmb from "./images/5m.jpg";
import imgheader from "./images/2.jpg";
import img2 from "./images/img2.jpg";
import imgheadermiini from "./images/9.jpg";
import imgheadermiinimb from "./images/9m.jpg";
import imgconfirmare from "./images/11.jpg";



const data = {
    introData: [{
        familia: "fam. Dulce",
        mire: "Mariana",
        mireasa: "Nicolai",
        data: "7 August 2022",
        data_confirmare: "29 iulie 2022",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheader,
        email: "rotarimariana489@gmail.com", 
       tel: "+373 69 451 965",
       phone: "tel:+37369451965",
       viber: "viber://chat?number=%2B37369451965",
       whatsapp: "https://wa.me/+37369451965",
       messenger: "https://www.messenger.com/t/rotari.mariana.169",
       tel1: "+373 787 78 418",
       phone1: "tel:+37378778418",
       viber1: "viber://chat?number=%2B37378778418",
       whatsapp1: "https://wa.me/+37378778418",
       messenger1: "https://www.messenger.com/t/carolina.talmazan.1",
    }],
    cardData: [
        {
            id: 1,
            img: biserica,
            title: "Cununia Religioasă și Sfântul Botez",
            localul: "Mănăstirea",
            name: "Ciuflea",
            data: "7 august 2022, duminică,",
            ora: "Botez: ora - 12:00",
            ora1: "Cununia: ora - 13:00",
            adress: "str. Ciuflea 12, Chișinău, Moldova",
            harta: "https://goo.gl/maps/ktjL4R5A82MU89XS9",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10892.148700719732!2d28.7480613!3d46.9609596!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8a5a5f5d7adcc261!2sTesalia!5e0!3m2!1sro!2s!4v1648740787752!5m2!1sro!2s"
        },
        {
            id: 2,
            img: restaurant,
            title: "Petrecerea Evenimentelor",
            localul: "Restaurantul",
            name: "Mognat",
            data: "7 august 2022, duminică,",
            ora: "ora 18:00",
            adress: "str. Prieteniei 2-4, Grătiești 2093, Moldova",
            harta: "https://goo.gl/maps/zkzn9Ny2UBPKquZV6",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11256.287616506279!2d26.7960057!3d45.1451398!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf6cc6d30a6b79fd5!2sConacul%20Trandafirilor!5e0!3m2!1sro!2s!4v1650352117838!5m2!1sro!2s" 
        },
       

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Salutare!",
            title2: "Mă numesc, Amelia!",
            mami: "",
            tati: "Nicolai & Mariana",
            message: "Împreună cu tati și mami, ",
            message1: "avem un anunț important de făcut! ",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Sfântul botez și Cununia Religioasă!",
            message: "Pentru că cele mai frumoase momente se sărbătoresc alături de cei dragi, vrem să fim înconjurați de cei mai apropiați și scumpi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celor mai semnificative evenimente din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            imgdesktop: imgOmSuflet,
            imgmobil: imgOmSufletmb,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            message1: "",
            parintii_nume1: "",
            parintii_nume2: "",
            parintii_nume3: "",
            parintii_nume4: "",
            nasii: "CU BINECUVÂNTAREA ȘI SUSȚINEREA NAȘILOR",
            nasii_nume: "Sergiu & Nadejda",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă ați fi alături de noi într-o zi atât de specială!",
            message: "",
        }
    ],

    blogDataConfirmare: [
        {
            imgdesktop: imgconfirmare,
            imgmobil: imgconfirmare,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
        }
    ],

}

export default data;